import React, { Component } from 'react'

import { Close } from './icons'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
    </div>
  )
}

class SubscribePopup extends Component {

  state = {
    modal: false, 
    popup: false,
    active: false,
    name: '',
    email: '',
  }

  _toggleModal = () => {
    this.setState({ 
      active: false
    })
    // document.querySelector('body').classList.toggle('menu-open')
    document.querySelector('.modal').classList.remove('active')
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({ result: 'success' }))
      .catch(error => this.setState({ result: 'fail', msg: error }))
  }

  render() {
    let { result } = this.state
    let props = {
      ref: 'form',
      name: 'pe_nation',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

      return(
        <>

          <Modal className='modal' show={this.state.active} handleClose={() => this._toggleModal()}>
            <section className="subscribe">
              <div className="subscribe__inner">
                <button className='modal__close' onClick={this._toggleModal}><span className='plus'></span></button>
                <h3>PE NATION X SHELTER PRO PACK COMPETITION</h3>
                { result === 'success' &&
                  <p className='success'>Thanks for registering for the competition! We will be in touch shortly.</p>
                }
                { result !== 'success' &&
                  <form {...props}>
                    <input type='text' name='name' placeholder='Name' onChange={this._handleChange} required  />
                    <input type='email' name='email' placeholder='Email' onChange={this._handleChange} required  />
                    <input type='text' name='phone' placeholder='Phone' onChange={this._handleChange} required  />
                    <button className='btn' type='submit'>Subscribe</button>
                  </form>
                }
              </div>
            </section>
          </Modal>
        </>
      )
  }
}


export default SubscribePopup
