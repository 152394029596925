import React, { Component } from 'react'

class LinkedIn extends Component {
	
	render() {

		const { colour } = this.props

		return (

			<svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-1331.000000, -34.000000)" fill={colour}>
            <g id="/icons/linkedin" transform="translate(1331.000000, 34.000000)">
              <path d="M0.30510254,3.87865079 L2.66271308,3.87865079 L2.66271308,12.0952255 L0.30510254,12.0952255 L0.30510254,3.87865079 Z M1.42381185,2.85090127 L1.40674318,2.85090127 C0.553309501,2.85090127 1.77635684e-15,2.22278909 1.77635684e-15,1.42738686 C1.77635684e-15,0.615720323 0.56966698,0 1.44016933,0 C2.30996049,0 2.84477893,0.614171341 2.8618476,1.42506339 C2.8618476,2.22046562 2.30996049,2.85090127 1.42381185,2.85090127 L1.42381185,2.85090127 Z M12.096,12.096 L9.422619,12.096 L9.422619,7.84327007 C9.422619,6.73032655 9.0044365,5.97132539 8.08486171,5.97132539 C7.38149012,5.97132539 6.99033302,6.48326393 6.80826717,6.97816366 C6.73999247,7.1547476 6.7506604,7.40181022 6.7506604,7.64964733 L6.7506604,12.096 L4.10217121,12.096 C4.10217121,12.096 4.13630856,4.56330081 4.10217121,3.87865079 L6.7506604,3.87865079 L6.7506604,5.16817826 C6.90712324,4.60434883 7.75344497,3.79965271 9.10400376,3.79965271 C10.7795786,3.79965271 12.096,4.98230042 12.096,7.52650327 L12.096,12.096 L12.096,12.096 Z" id="Shape"></path>
            </g>
	        </g>
		    </g>
			</svg>

		)

	}

}

class Instagram extends Component {

	render() {

		const { colour } = this.props

		return (
			<svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="/g/address-Black-" transform="translate(0.000000, -147.000000)" fill={colour}>
            <g id="/i/insta" transform="translate(0.000000, 147.000000)">
              <path d="M10.4832,6.37549714 C10.4832,4.10770286 8.64329143,2.26779429 6.37549714,2.26779429 C4.10770286,2.26779429 2.26779429,4.10770286 2.26779429,6.37549714 C2.26779429,8.64329143 4.10770286,10.4832 6.37549714,10.4832 C8.64329143,10.4832 10.4832,8.64329143 10.4832,6.37549714 M10.3976229,1.71154286 C10.03392,1.71154286 9.7344,2.01106286 9.7344,2.37476571 C9.7344,2.73846857 10.03392,3.03798857 10.3976229,3.03798857 C10.7613257,3.03798857 11.0608457,2.73846857 11.0608457,2.37476571 C11.0608457,1.98966857 10.7613257,1.71154286 10.3976229,1.71154286 M3.01659429,0.7488 L9.7344,0.7488 C10.9752686,0.7488 12.0021943,1.75433143 12.0021943,3.01659429 L12.0021943,9.7344 C12.0021943,10.9752686 10.9966629,12.0021943 9.7344,12.0021943 L3.01659429,12.0021943 C1.77572571,12.0021943 0.7488,10.9966629 0.7488,9.7344 L0.7488,3.01659429 C0.7488,1.77572571 1.77572571,0.7488 3.01659429,0.7488 M9.7344,-4.61852778e-14 L3.01659429,-4.61852778e-14 C1.36923429,-4.61852778e-14 -4.61852778e-14,1.34784 -4.61852778e-14,3.01659429 L-4.61852778e-14,9.7344 C-4.61852778e-14,11.38176 1.34784,12.7509943 3.01659429,12.7509943 L9.7344,12.7509943 C11.4031543,12.7509943 12.7509943,11.4031543 12.7509943,9.7344 L12.7509943,3.01659429 C12.7509943,1.34784 11.4031543,-4.61852778e-14 9.7344,-4.61852778e-14 M3.12356571,6.37549714 C3.12356571,4.57837714 4.57837714,3.12356571 6.37549714,3.12356571 C8.17261714,3.12356571 9.62742857,4.57837714 9.62742857,6.37549714 C9.62742857,8.17261714 8.17261714,9.62742857 6.37549714,9.62742857 C4.57837714,9.62742857 3.12356571,8.17261714 3.12356571,6.37549714" id="Shape"></path>
            </g>
	        </g>
		    </g>
			</svg>
		)
	}

}

class Behance extends Component {

	render() {

		const { colour } = this.props

		return (
			<svg width="17px" height="11px" viewBox="0 0 17 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-33.000000, -148.000000)" fill={colour}>
            <g transform="translate(33.000000, 148.000000)">
              <path d="M4.61312,4.3776 L2.0672,4.3776 L2.0672,2.16576 L4.61312,2.16576 C5.17888,2.16576 5.6576,2.67264 5.6576,3.27168 C5.6576,3.87072 5.17888,4.3776 4.61312,4.3776 L4.61312,4.3776 Z M8.24704,7.6032 C8.24704,6.42816 7.5072,5.4144 6.50624,5.04576 C7.22432,4.56192 7.7248,3.6864 7.7248,2.71872 C7.70304,1.22112 6.54976,2.13162821e-14 5.15712,2.13162821e-14 L-4.26325641e-14,2.13162821e-14 L-4.26325641e-14,10.87488 L5.15712,10.87488 C6.83264,10.87488 8.24704,9.42336 8.24704,7.6032 L8.24704,7.6032 Z M10.79296,7.07328 L16.45056,7.07328 L16.45056,6.7968 C16.45056,4.53888 14.92736,2.71872 12.59904,2.71872 C10.27072,2.71872 8.74752,4.56192 8.74752,6.7968 C8.74752,9.05472 10.27072,10.87488 12.59904,10.87488 C14.9056,10.87488 16.01536,9.46944 16.36352,7.6032 L14.27456,7.6032 C14.10048,8.57088 13.55648,9.23904 12.59904,9.23904 C11.61984,9.23904 10.88,8.2944 10.79296,7.07328 L10.79296,7.07328 Z M14.29632,5.9904 L10.90176,5.9904 C11.01056,5.02272 11.70688,4.3776 12.59904,4.3776 C13.4912,4.3776 14.16576,5.02272 14.29632,5.9904 L14.29632,5.9904 Z M10.29248,0.52992 L14.9056,0.52992 L14.9056,2.14272 L10.29248,2.14272 L10.29248,0.52992 Z M2.0672,5.9904 L4.61312,5.9904 C5.17888,5.9904 6.15808,6.26688 6.15808,7.39584 C6.15808,7.99488 5.70112,8.70912 5.13536,8.70912 L2.0672,8.70912 L2.0672,5.9904 Z" id="Shape"></path>
            </g>
	        </g>
		    </g>
			</svg>
		)
	}

}

class DownArrow extends Component {

	render() {

		const { colour } = this.props

		return (
			<svg width="22px" height="13px" viewBox="0 0 22 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-710.000000, -704.000000)" fill={colour}>
            <g transform="translate(708.000000, 703.000000)">
              <polygon transform="translate(12.897218, 7.200000) rotate(-270.000000) translate(-12.897218, -7.200000) " points="18.8972176 7.19992309 18.0902245 6.33243913 8.79366427 -3.6 6.89721762 -1.86500663 15.3787794 7.19999949 6.89721762 16.2650056 8.79366427 18 18.0902245 8.06755985"></polygon>
            </g>
	        </g>
		    </g>
			</svg>
		)

	}

}

class Logo extends Component {

	render() {

		const { colour } = this.props

		return (

			<svg width="15px" height="14px" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-111.000000, -47.000000)" fill={colour}>
            <g>
              <g>
                <g transform="translate(111.000000, 47.000000)">
                  <path d="M6.20844,8.26554 L7.20024,5.40012 L8.19222,8.26554 L6.20844,8.26554 Z M9.82686,13.0413 L14.40054,13.0413 L9.33096,0.55098 L5.06964,0.55098 L0,13.0413 L4.57362,13.0413 L5.06964,11.60856 L9.33096,11.60856 L9.82686,13.0413 Z" id="Fill-1"></path>
                </g>
              </g>
            </g>
	        </g>
		    </g>
			</svg>

		)
	}

}


class Spotify extends Component {

	render() {

		const { colour } = this.props

		return (

			<svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
			  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			    <g transform="translate(-756.000000, -47.000000)" fill={colour}>
			      <g>
			        <g>
			          <g transform="translate(756.000000, 47.000000)">
			            <path d="M12.7835821,6.42537313 C12.6044776,6.69402985 12.1567164,6.80597015 11.8208955,6.60447761 C9.44776119,5.19402985 5.46268657,5.03731343 3.20149254,5.75373134 C2.82089552,5.86567164 2.46268657,5.64179104 2.35074627,5.30597015 C2.23880597,4.92537313 2.46268657,4.56716418 2.79850746,4.45522388 C5.44029851,3.67164179 9.78358209,3.82835821 12.5373134,5.46268657 C12.8731343,5.64179104 12.9850746,6.08955224 12.7835821,6.42537313 M11.7537313,8.70895522 C11.5746269,8.97761194 11.238806,9.08955224 10.9701493,8.8880597 C8.95522388,7.65671642 5.91044776,7.29850746 3.58208955,8.03731343 C3.29104478,8.10447761 2.95522388,7.97014925 2.86567164,7.65671642 C2.79850746,7.36567164 2.93283582,7.02985075 3.24626866,6.94029851 C5.95522388,6.1119403 9.31343284,6.53731343 11.619403,7.94776119 C11.8208955,8.05970149 11.9328358,8.44029851 11.7537313,8.70895522 M10.858209,10.7462687 C10.7014925,10.9701493 10.4552239,11.0373134 10.2313433,10.9029851 C8.48507463,9.82835821 6.29104478,9.60447761 3.69402985,10.1865672 C3.42537313,10.2537313 3.20149254,10.0746269 3.13432836,9.85074627 C3.06716418,9.58208955 3.24626866,9.35820896 3.47014925,9.29104478 C6.29104478,8.6641791 8.75373134,8.91044776 10.6791045,10.119403 C10.9253731,10.2313433 10.9701493,10.5223881 10.858209,10.7462687 M7.43283582,-5.32907052e-15 C3.3358209,-5.32907052e-15 -5.4534155e-13,3.3358209 -5.4534155e-13,7.43283582 C-5.4534155e-13,11.5298507 3.3358209,14.8656716 7.43283582,14.8656716 C11.5298507,14.8656716 14.8656716,11.5298507 14.8656716,7.43283582 C14.8656716,3.3358209 11.5522388,-5.32907052e-15 7.43283582,-5.32907052e-15" id="Shape"></path>
			          </g>
			        </g>
			      </g>
			    </g>
			  </g>
			</svg>

		)
	}

}

class Play extends Component {

	render() {

		const { colour } = this.props

		return (

			<svg width="6px" height="8px" viewBox="0 0 6 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-801.000000, -51.000000)" fill={colour}>
            <g>
              <g>
                <g transform="translate(801.000000, 51.000000)">
                  <polygon points="-4.97379915e-14 -1.70530257e-13 5.432022 3.608766 -4.97379915e-14 7.21602"></polygon>
                </g>
              </g>
            </g>
	        </g>
		    </g>
			</svg>

		)
	}

}

class Facebook extends Component {

	render() {

		const { colour } = this.props

		return (
						
			<svg width="9px" height="19px" viewBox="0 0 9 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g transform="translate(-840.000000, -375.000000)" fill={colour}>
            <g transform="translate(840.000000, 375.000000)">
              <path d="M5.86793103,5.43016393 L5.86793103,3.96819672 C5.86793103,3.28196721 6.33862069,3.10295082 6.71517241,3.10295082 L8.84896552,3.10295082 L8.84896552,-5.32907052e-15 L5.89931034,-5.32907052e-15 C2.63586207,-5.32907052e-15 1.91413793,2.29737705 1.91413793,3.78918033 L1.91413793,5.43016393 L3.8191672e-14,5.43016393 L3.8191672e-14,7.60819672 L3.8191672e-14,9.04032787 L1.91413793,9.04032787 L1.91413793,18.0806557 L5.71103448,18.0806557 L5.71103448,9.04032787 L8.53517241,9.04032787 L8.66068966,7.60819672 L8.88034483,5.40032787 L5.86793103,5.40032787 L5.86793103,5.43016393 Z" id="Path"></path>
            </g>
	        </g>
		    </g>
			</svg>

		)
	}

}

class Close extends Component {
  render() {
    return (
      <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
		    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="Newsletter-Register" transform="translate(-980.000000, -154.000000)" fill="#026BB4">
	          <path d="M992.5,154 C985.60712,154 980,159.607121 980,166.5 C980,173.39288 985.60712,179 992.5,179 C999.392879,179 1005,173.39288 1005,166.5 C1005,159.607121 999.392879,154 992.5,154 Z M997.588137,170.358777 L996.357696,171.589218 L992.50012,167.730441 L988.641343,171.588018 L987.412073,170.357577 L991.269649,166.5 L987.412073,162.641224 L988.641343,161.410783 L992.50012,165.26956 L996.358896,161.410783 L997.589337,162.641224 L993.73056,166.5 L997.588137,170.358777 Z"></path>
	        </g>
		    </g>
			</svg>
    )
  }
}


export { LinkedIn, Instagram, Behance, DownArrow, Logo, Spotify, Play, Facebook, Close }
