import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Client from "shopify-buy"

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

import ogImage from '../assets/images/facebook-share.jpg'

class Layout extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isCartOpen: false,
      cartRequest: false,
      checkout: { lineItems: [] },
      products: [],
      shop: {},
    }
  }

  componentDidMount() {
    this.client = Client.buildClient({
      domain: "store.the-shelter.com.au",
      storefrontAccessToken: "87590e80491e6df8e371236528b1f727",
    })
    window.client = this.client
    this.client.checkout.create().then((checkout) => {
      window.checkout = checkout
      this.setState({ checkout })
    })
  }

  _fetchProducts(query) {
    this.client.product.fetchAll().then((products) => {
      this.setState({ ...this.state, products })
    })
  }

  _addVariantToCart(variantId, quantity, properties) {
    this.setState({ isCartOpen: true, cartRequest: true })

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10), customAttributes: properties }]
    const checkoutId = this.state.checkout.id

    console.log(checkoutId, lineItemsToAdd)

    return this.client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((checkout) => {
        console.log(checkout)
        this.setState({ checkout, cartRequest: false })
      })
  }

  _updateQuantityInCart(lineItemId, quantity) {
    const checkoutId = this.state.checkout.id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  _removeLineItemInCart(lineItemId) {
    const checkoutId = this.state.checkout.id
    this.setState({ isCartOpen: true, cartRequest: true })

    return this.client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then((checkout) => this.setState({ checkout, cartRequest: false }))
  }

  render() {
    let { children, location } = this.props
    const { checkout, isCartOpen, cartRequest, products } = this.state

    children = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        addVariantToCart: this._addVariantToCart.bind(this),
        updateQuantityInCart: this._updateQuantityInCart.bind(this),
        removeLineItemInCart: this._removeLineItemInCart.bind(this),
        fetchProducts: this._fetchProducts.bind(this),
        checkout: checkout,
        products: products,
      })
    )

    return (
      <>
        <Helmet
          title={`The Shelter`}
          meta={[
            { name: 'description', content: 'A space designed to feel like a spa, and work like a gym. Inspired by the idea to combine five distinct, yet inter related wellness concepts under the one roof. Ice Baths, Saunas, Spin, Boxing, Juicery.' },
            { name: 'keywords', content: 'wellness, work, gym, spa, ice baths, saunas, spin, boxing, juicery' },
          ]}
        >
          <html lang="en" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:image" content={ogImage} />
          <meta property="og:image:url" content={ogImage} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Shelter - Double Bay | Feels like a spa. Works like gym." />
          <meta property="og:description" content="A space designed to feel like a spa, and work like a gym. Inspired to combine five distinct, yet inter related wellness concepts under the one roof." />
          <meta property="og:site_name" content="Shelter" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:description" content="A space designed to feel like a spa, and work like a gym. Inspired to combine five distinct, yet inter related wellness concepts under the one roof." />
          <meta name="twitter:title" content="Shelter - Double Bay | Feels like a spa. Works like gym." />
        </Helmet>
        <Header 
          pathname={location.pathname}
          isCartOpen={isCartOpen}
          setIsCartOpen={(val) => this.setState({ isCartOpen : val })}
          cartRequest={cartRequest}
          cartCount={checkout.lineItems.length}
          checkout={checkout}
          updateQuantityInCart={this._updateQuantityInCart.bind(this)}
          removeLineItemInCart={this._removeLineItemInCart.bind(this)}
        />
        <main className="site-wrapper">
          { children }
        </main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
