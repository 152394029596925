import React from "react"
import PropTypes from "prop-types"

import Link from "../utils/link"

import ExitIcon from "../assets/images/close.svg"
import Trash from "../assets/images/trash.svg"

const Cart = ({ toggleIsCartExpanded, isCartOpen, checkout, updateQuantityInCart }) => {
  const clickRef = React.useRef()

  const decreaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity - 1)
  }

  const increaseQuantity = (id, quantity) => {
    updateQuantityInCart(id, quantity + 1)
  }


  return (
    <div className={isCartOpen ? 'cart active' : 'cart'} ref={clickRef}>
      <div className="cart__head">
        <div className="cart__head__title">Cart</div>
        <div className="cart__head__close">
          <img src={ExitIcon} onClick={toggleIsCartExpanded} />
        </div>
      </div>
      {checkout.lineItems.length === 0 ? (
        <div className="cart__empty">Cart Empty</div>
      ) : (
        <div className="cart__items">
          {checkout.lineItems?.map((item, index) => {
            return (
              <div className="cart__item" key={index}>
                <div
                  className="cart__image"
                  style={{ backgroundImage: `url(${item.variant.image.src})` }}
                />
                <div className="cart__details">
                  <div className="cart__item-title">{item.title}</div>
                  {item.variant.title !== 'Default Title' &&
                    <div className="cart__item-variant">
                      {item.variant.title}
                    </div>
                  }
                  {item.customAttributes.length > 0 &&
                    <ul class='cart__properties'>
                      {item.customAttributes?.map((el, i) => (
                        <li key={i}>{el.key}: {el.value}</li>
                      ))}
                    </ul>
                  }
                  <div className=''>${item.variant.price?.amount}</div>
                  <div className="cart__quantity">
                    <div className="cart__quantity-text">Qty</div>
                    <div
                      className="cart__quantity-control"
                      onClick={() => decreaseQuantity(item.id, item.quantity)}
                    >
                      –
                    </div>
                    <div className="cart__quantity-value">
                      {item.quantity}
                    </div>
                    <div
                      className="cart__quantity-control"
                      onClick={() => increaseQuantity(item.id, item.quantity)}
                    >
                      +
                    </div>
                  </div>
                  <div
                    className="cart__remove"
                    onClick={() => decreaseQuantity(item.id, 0)}
                  >
                    <img src={Trash} alt='Remove item from cart' />
                  </div>
                </div>
              </div>
            )

          })}
        </div>
      )}
      {checkout.lineItems.length > 0 && (
        <div className='cart__total'>
          <div className="cart__total-wrap">
            <div className="cart__total-label">Total</div>
            <div className="cart__total-amount">$ {checkout.totalPrice?.amount} AUD</div>
          </div>
          <Link to={checkout.webUrl} className="cart__checkout btn">
            Checkout
          </Link>
        </div>
      )}
    </div>
  )
}

Cart.propTypes = {
  toggleIsCartExpanded: PropTypes.func.isRequired,
  checkout: PropTypes.object.isRequired,
  updateQuantityInCart: PropTypes.func.isRequired,
}

export default Cart
