import React, { Component } from 'react'
import { Link } from 'gatsby'
import SubscribePopup from './subscribe-popup'

import Cart from './cart'

import logo from '../assets/images/logo.svg'
import logoLight from '../assets/images/logo-light.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class ContactForm extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    question: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({ result: 'success' }))
      .catch(error => this.setState({ result: 'fail', msg: error }))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<div className='register__success'><span className='btn btn--black'>We’ll be in touch shortly.</span><p><a href='/athome'>Back to Workout at Home</a></p></div>)

    return (
      <form {...props} className='register__form form'>
        <p>Enter your email below &amp; we'll be in touch.</p>
        <div className='form__row'>
          <input className="text" type="text" name="name" placeholder="Name" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input className="text" type="email" name="email" placeholder="Email" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input className="text" type="text" name="phone" placeholder="Phone" onChange={this.handleChange} />
        </div>
        <div className='form__row'>
          <textarea className="text" name="question" placeholder="Ask us a question" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <button className="btn btn--black">Submit</button>
        </div>
      </form>
    )
  }
}

class AtHomeForm extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    question: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({ result: 'success' }))
      .catch(error => this.setState({ result: 'fail', msg: error }))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'athome',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<div className='register__success'><p>Success!</p></div>)

    return (
      <form {...props} className='register__form form'>
        <p>Enter your email below &amp; we'll be in touch.</p>
        <div className='form__row'>
          <input className="text" type="text" name="name" placeholder="Name" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input className="text" type="email" name="email" placeholder="Email" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input className="text" type="text" name="phone" placeholder="Phone" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <textarea className="text" name="question" placeholder="Ask us a question" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <button className="btn btn--black">Submit</button>
        </div>
      </form>
    )
  }
}

class PeForm extends Component {

  state = {
    name: '',
    email: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => this.setState({ result: 'success' }))
      .catch(error => this.setState({ result: 'fail', msg: error }))
  }

  render() {
    let props = {
      ref: 'form',
      name: 'pe_nation',
      onSubmit: this.handleSubmit,
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    if (this.state.result === 'success')
      return (<div className='register__success'><p>Thanks for entering the competition. We'll be in touch soon.</p></div>)

    return (
      <form {...props} className='register__form form'>
        <p>PE NATION X SHELTER PRO PACK COMPETITION</p>
        <div className='form__row'>
          <input className="text" type="text" name="name" placeholder="Name" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <input className="text" type="email" name="email" placeholder="Email" onChange={this.handleChange} required />
        </div>
        <div className='form__row'>
          <button className="btn btn--black">Submit</button>
        </div>
      </form>
    )
  }
}

class Header extends Component {

  state = {
    scrolled: false,
    scrollDirection: 'up',
    prevOffset: 0,
    active: false,
    menuActive: false,
    workoutActive: false,
    studioActive: false,
    classesActive: false,
    spaActive: false,
    spacesActive: false,
    formActive: false,
    formAtHomeActive: false,
    formPeActive: false,
    name: '',
    email: ''
  }

  constructor(props) {
    super(props)

    this._toggleOffCanvas = this._toggleOffCanvas.bind(this)
    this._hideOffCanvas = this._hideOffCanvas.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this._openStudio = this._openStudio.bind(this)
    this._openWorkout = this._openWorkout.bind(this)
    this._openClasses = this._openClasses.bind(this)
    this._openSpa = this._openSpa.bind(this)
    this._openSpaces = this._openSpaces.bind(this)
    this._toggleForm = this._toggleForm.bind(this)
    this._toggleAtHomeForm = this._toggleAtHomeForm.bind(this)
    this._togglePeForm = this._togglePeForm.bind(this)
  }

  componentDidMount() {
    require('smoothscroll-polyfill').polyfill()
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window && window.scrollY > 50) {
      this.setState({scrolled: true});
    } else {
      this.setState({scrolled: false});
    }
    let scrollY = window && window.scrollY
    if (scrollY === 0) {
      this.setState({scrollDirection: 'up'})
    }
    if (scrollY > this.state.prevOffset && window.scrollY > 5) {
      this.setState({scrollDirection: 'down'})
    } else if (scrollY < this.state.prevOffset) {
      this.setState({scrollDirection: 'up'})
    }
    this.setState({prevOffset: scrollY})
  }

  _toggleOffCanvas() {
    if ( this.state.menuActive === true ) {
      this.setState({menuActive: false});
      document.querySelector('body').classList.remove('menu-open');
    } else {
      this.setState({menuActive: true});
      document.querySelector('body').classList.add('menu-open');
    }
  }

  _hideOffCanvas() {
    this.setState({
      menuActive: false,
      studioActive: false,
      workoutActive: false,
      classesActive: false,
      spaActive: false,
      spacesActive: false
    });
    document.querySelector('body').classList.remove('menu-open');
  }

  _openStudio() {
    this.setState({studioActive: !this.state.studioActive});
  }

  _openWorkout() {
    this.setState({workoutActive: !this.state.workoutActive});
  }

  _openClasses() {
    this.setState({classesActive: !this.state.classesActive});
  }

  _openSpa() {
    this.setState({spaActive: !this.state.spaActive});
  }

  _openSpaces() {
    this.setState({spacesActive: !this.state.spacesActive});
  }

  _toggleForm() {
    document.querySelector('.register').classList.toggle('active');
  }
  _toggleAtHomeForm() {
    document.querySelector('.register--athome').classList.toggle('active');
  }
  _togglePeForm() {
    document.querySelector('.register--penation').classList.toggle('active');
  }
  _toggleSatNav() {
    document.querySelector('.off-canvas--sat').classList.toggle('active');
  }

  render() {

    let _this = this

    let { 
      pathname,
      isCartOpen,
      setIsCartOpen,
      cartRequest,
      cartCount,
      checkout,
      updateQuantityInCart,
      removeLineItemInCart
    } = this.props

    const toggleIsCartExpanded = () => {
      setIsCartOpen(!isCartOpen)
    }

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
      partiallyActive: true
    }
    let satNavProps = {
      onClick: this._toggleSatNav,
      activeClassName: 'active'
    }
    let subProps = {
      activeClassName: 'active',
      onClick: (e) => {
        _this._hideOffCanvas()
        const id = e.currentTarget.getAttribute('href').split('#')[1]
        setTimeout(() => {
          window.scroll({
            top: document.getElementById(id).getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth'
          })
        }, 100)
      }
    }
    let openStudio = {
      onClick: this._openStudio
    }
    let openWorkout = {
      onClick: this._openWorkout
    }
    let openClasses = {
      onClick: this._openClasses
    }
    let openSpa = {
      onClick: this._openSpa
    }
    let openSpaces = {
      onClick: this._openSpaces
    }

    return (
      <div>
        <header id='normal' className={`${this.state.scrolled ? 'header scrolled' : 'header'} ${this.state.scrollDirection}`} role='banner'>
          <div className='header__inner'>
            <Link to="/" className="header__logo">
              <img className='dark' src={logo} alt="The Shelter" />
              <img className='light' src={logoLight} alt="The Shelter" />
            </Link>
            <div className='header__actions'>
              <button onClick={this._toggleSatNav} className={ this.state.menuActive ? 'burger active' : 'burger' }>
                <span className="lines"></span>
              </button>
            </div>
          </div>
        </header>
        <div id="normal-off" className={this.state.satNavActive ? 'off-canvas off-canvas--sat active' : 'off-canvas off-canvas--sat'}>
          <div className='off-canvas__close' onClick={e => this._toggleSatNav()}>
            <span className='plus'></span>
          </div>
          <ul className="off-canvas__nav">
            <li><a href="/" target='_blank' rel='noopener noreferrer'>Shop</a></li>
            <li><a href="https://ondemand.the-shelter.com.au" target="_blank" rel="noopener noreferrer">On Demand</a></li>
            <li><a href="https://ondemand.the-shelter.com.au" target="_blank" rel="noopener noreferrer">Workout Library</a></li>
            <li onClick={e => this._toggleForm()}><span>Get In Touch</span></li>
          </ul>
        </div>
        <div className={this.state.formActive ? 'register active' : 'register'}>
          <div className='register__close' onClick={e => this._toggleForm()}>
            <span className='plus'></span>
          </div>
          <div className='register__wrapper'>
            <ContactForm />
          </div>
        </div>
        <div className={this.state.proActive ? 'register--athome active' : 'register--athome'}>
          <div className='register__close' onClick={e => this._toggleAtHomeForm()}>
            <span className='plus'></span>
          </div>
          <div className='register__wrapper'>
            <AtHomeForm />
          </div>
        </div>
        <div className={this.state.proActive ? 'register--penation active' : 'register--penation'}>
          <div className='register__close' onClick={e => this._togglePeForm()}>
            <span className='plus'></span>
          </div>
          <div className='register__wrapper'>
            <PeForm />
          </div>
        </div>
        <Cart
          toggleIsCartExpanded={toggleIsCartExpanded}
          isCartOpen={isCartOpen}
          cartRequest={cartRequest}
          cartCount={cartCount}
          checkout={checkout}
          updateQuantityInCart={updateQuantityInCart}
          removeLineItemInCart={removeLineItemInCart}
        />
      </div>
    )
  }
}

export default Header
