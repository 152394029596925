import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade';

import logo from '../assets/images/logo.png'
import app from '../assets/images/app.png'
import instagram from '../assets/images/instagram.png'

class Footer extends Component {
  render() {

    return (
      <div>
        <Fade bottom cascade distance="20px">
          <div className='footer footer--athome'>
            <div className='footer__inner'>
              <div className="col">
                <ul>
                  <li><a href="https://instagram.com/shelter" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="The Shelter - Instagram" /></a></li>
                  <li><a href="mailto:athome@the-shelter.com.au">athome@the-shelter.com.au</a></li>
                </ul>
              </div>
              <div className="col">
                <p>copyright &copy; SHELTER {new Date().getFullYear()}</p>
              </div>
            </div>
          </div>
          <footer className='footer footer--normal'>
            <div className='footer__inner'>
              <div className="col sat-hide">
                <p>opening hours:</p>
                <p><span className="first">Mon: 6am - 12pm, 5pm - 8pm</span><br />
                <span className="first">Tue: 5am - 2.30pm, 5pm - 8pm</span><br />
                <span className="first">Wed: 6am - 2.30pm, 5pm - 8pm</span><br />
                <span className="first">Thu: 5am - 2.30pm, 5pm - 8pm</span><br />
                <span className="first">Fri: 6am - 2.30pm</span><br />
                <span className="first">Sat: 7am - 3pm</span><br />
                <span className="first">Sun: 7am - 3pm</span></p>
              </div>
              <div className="col">
                <p className='sat-hide'>contact:</p>
                <p className='sat-hide'>3 Goldman Lane, Double Bay, Sydney 2028</p>
                <p className='sat-hide'><a href="mailto:hello@the-shelter.com.au">hello@the-shelter.com.au</a></p>
                <p className='sat-hide'><a href="tel:+61403264218">+61 403 264 218</a></p>
              </div>
              <div className="col col--logos">
                <ul>
                  <li><a href="https://itunes.apple.com/au/app/shelter-sydney/id1438016531?mt=8" target="_blank" rel="noopener noreferrer"><img src={app} alt="The Shelter - App" /></a></li>
                  <li><a href="https://instagram.com/shelter" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="The Shelter - Instagram" /></a></li>
                </ul>
                <img src={logo} alt="The Shelter" />
              </div>
            </div>
            <div className='footer__inner'>
              <div className="col">
                <ul>
                  <li><Link to="https://shelter.codedrips.com/wp-content/uploads/2024/07/SHELTER-CO-PTY-LTD-TERMS-AND-CONDITIONS-AS-AT-JULY-15-2024.pdf" target='_blank' rel='noopener noreferrer'>terms &amp; conditions</Link></li>
                  <li className='sat-hide'><a href="mailto:hello@the-shelter.com.au">work with us</a></li>
                  <li className='sat-hide'><a href="/register">register</a></li>
                </ul>
              </div>
              <div className="col">
                <p>copyright &copy; SHELTER {new Date().getFullYear()}</p>
              </div>
            </div>
          </footer>
        </Fade>
      </div>
    )
  }
}

export default Footer
